﻿import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map'
import { UserService } from './user.service';
import { User } from '../_models/user';

@Injectable()
export class AuthenticationService {
    userService = UserService; currentUser: User; currentUsername: any;
    constructor(private http: HttpClient) { }

    /****** mini.luxasia.com ******/
    //PROD URL
    //apiurl = 'http://116.12.52.170/plesk-site-preview/api.complimentarygoods.luxasia.com/api/cmg/'; 

    //UAT URL
    //apiurl = 'http://116.12.52.170/plesk-site-preview/uatcmgapi.complimentarygoods.luxasia.com/api/cmg/';

    /****** plesk.luxasia.com ******/
    //PROD URL
    apiurl = 'https://plesk.luxasia.com/plesk-site-preview/api.complimentarygoods.luxasia.com/api/cmg/';

    //UAT URL
    //apiurl = 'http://115.42.209.163/plesk-site-preview/uatcmgapi.complimentarygoods.luxasia.com/api/cmg/';

    // apiurl = 'http://localhost:9287/api/cmg/'; /* local URL*/ 

    login(username: string, password: string) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<any>(this.apiurl + 'GetCustomer/' + username + '/' + password)
            .map(user => {
                if (user) {
                    user.ComplimentaryGoodsValue = parseFloat(user.ComplimentaryGoodsValue.toFixed(2));
                    this.userService.prototype.setUser(user);
                    
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUsername = user.UserName;

                }

                return user;
            });
    }
    TermsChange(value: any) {
        if (value) {
            this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
            return this.http.get<any>(this.apiurl + 'UpdateTerms/' + this.currentUser.CustomerId)
                .map(user => {
                    return user;
                });
        }
    }
    create(username: string) {
        return this.http.get<any>(this.apiurl + 'ForGotPassword/' + username + '/')
            .map(user => {
                return user;
            });
    }
    //resetPassword(email: string, username: string) {
    //    return this.http.get<any>(this.apiurl + 'ResetPassword/' + email + '/' + username)
    //        .map(user => {
    //            return user;
    //        });
    //}
    forgot(key: string, newpassword: any, cnfPassword: any) {
        return this.http.get<any>(this.apiurl + 'ForGotPassword/' + key + '/')
            .map(user => {
                return user;
            });
    }
    resetPassword(username: string) {
        return this.http.get<any>(this.apiurl + 'ForGotPassword/' + username + '/')
            .map(user => {
                return user;
            });
    }
    changepassword(oldpassword: string, newpassword: any) {
        return this.http.get<any>(this.apiurl + 'ChangePassword/' + this.currentUsername + '/' + newpassword + '/' + oldpassword + '/')
            .map(user => {
                if (user) {
                    user.ComplimentaryGoodsValue = parseFloat(user.ComplimentaryGoodsValue.toFixed(2));
                    this.userService.prototype.setUser(user);
                    
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUsername = user.UserName;

                }

                return user;
            });
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
    }

    ValidateDownTime() { //true - down, false - up
        return this.http.get<any>(this.apiurl + 'SiteAccessibility/')
            .map(user => {
                if (this.currentUsername == '30005792') {
                    return false;
                } else {
                    return user;
                }

                //return true; //restrict users to place new orders
                //return user;
                //return true;
              });
    }

    ValidateBatchProcessingDownTime() {
        return this.http.get<any>(this.apiurl + 'CheckIfBatchJobProcessing/')
            .map(user => {
                return user;
            });
    }
}